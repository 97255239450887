export const feature_activation_keys = {
  is_whitelabelled_whatsapp_allowed: {
    value: false,
    key: "is_whitelabelled_whatsapp_allowed",
  },
  is_vdeocipher_disabled: {
    value: false,
    key: "is_vdeocipher_disabled",
  },
  is_auto_debit_enabled: {
    value: false,
    key: "is_auto_debit_enabled",
  },
  enable_leadgen: {
    value: false,
    key: "enable_leadgen",
  },
  is_premium: {
    value: false,
    key: "is_premium",
  },
  enable_media_library: {
    value: false,
    key: "enable_media_library",
  },
  is_leaderboard_enabled: {
    value: false,
    key: "is_leaderboard_enabled",
  },
  is_ads_enabled: {
    value: false,
    key: "is_ads_enabled",
  },
  is_account_details_active: {
    value: false,
    key: "is_account_details_active",
  },
  is_refunds_enabled: {
    value: false,
    key: "is_refunds_enabled",
  },
  is_zoom_attendance_enabled: {
    value: false,
    key: "is_zoom_attendance_enabled",
  },
  is_exlycon_active: {
    key: "is_exlycon_active",
    value: false,
  },
  is_live_analytics_active: {
    key: "is_live_analytics_active",
    value: false,
  },
  is_zoom_active: {
    key: "is_zoom_active",
    value: false,
  },
};
