import React from "react";
import useInfiniteSearch from "hooks/useInfiniteSearch";
import { reorderListingsBasedOnStatus } from "features/Listings/utils/reorderListingsBasedOnStatus";
import { OFFERING_STATUSES } from "constants/schedule";
import { useOfferings } from "hooks/useOfferings";

const default_offering_status_to_show = [OFFERING_STATUSES.live.value];
const defaultFormatOptions = (options) =>
  options.map((listing) => ({
    ...listing,
    value: listing.uuid, // we always need this prop to define the value that will be passed to onChange function
  }));

// TODO: add functionality to fetch details of the selected offering if its not in the 1st page itself
// reference
//    - src/schedule-v2/modals/recorded-content/AddLiveSession/useLiveSessionOfferingOptions.js
//    - author @harshit
/**
 * hook uses InfiniteSearch to call all pages of the offering list
 * to be used in combination with ExlyOfferingSelect component
 */
export const useInfiniteListingOptions = ({
  offeringStatusesToShow = default_offering_status_to_show,
  formatOptions = defaultFormatOptions,
  isOfferingVariantsEnabled,
} = {}) => {
  const {
    data: options,
    loading,
    setLoading,
    setError,
    setHasMore,
    pageNumber,
    setData,
    lastElementRef,
  } = useInfiniteSearch();

  const { fetchOfferingsList } = useOfferings();

  React.useEffect(() => {
    setLoading(true);
    setError(false);

    const statusString = offeringStatusesToShow.join(",");
    fetchOfferingsList({
      page: pageNumber,
      status: statusString,
      is_offering_variants_enabled: isOfferingVariantsEnabled,
    })
      .then((response) => {
        const { listings, total_pages } = response?.data || {};
        setHasMore(total_pages > pageNumber);
        setData((prevData) =>
          reorderListingsBasedOnStatus(
            [...prevData, ...formatOptions(listings)],
            offeringStatusesToShow
          )
        );
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [pageNumber]);

  return {
    loading,
    options,
    lastElementRef,
  };
};
