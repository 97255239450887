// TODO: @dhruva to revamp this file and remove old exly connect code

/* eslint-disable react-hooks/exhaustive-deps */
import { ExlyInput } from "common/form";
import { dataProvider } from "data";
import moment from "moment";
import React from "react";
import styles from "./ExlyConnectMeetingCard.module.css";
import { Card, Button, Modal } from "@my-scoot/component-library-legacy";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Popover from "@material-ui/core/Popover";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import { composeValidators } from "utils/validations";
import {
  alphaNumeric,
  atLeastOneAlphabet,
  atLeastOneNumber,
  maxLength,
  minLength,
  noSpaces,
  required,
} from "common/validate";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { EXLY_CONNECT_APIS } from "features/ExlyConnect/ExlyConnect.api";
import { format } from "date-fns";
import {
  DATE_WITH_FULL_MONTH_FORMAT,
  DEFAULT_TIME_FORMAT_12,
} from "constants/date-fns/dateTime.constant";
import { logError } from "utils/error";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "@my-scoot/exly-react-component-lib";

/**
 * TODO: Incase the commented code from this component is still there till  19 July 2025, remove them
 */
export const ExlyConnectMeetingCard = ({
  data,
  hideTime = false,
  hideDate = false,
  hideBroadcastMeetingLink = false,
}) => {
  const { notify } = useNotifications();
  // const permissions = JSON.parse(
  //   sessionStorage.getItem("menu_permissions") ?? null
  // );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fetchingBackupZoomLinkDetails, setFetchingBackupZoomLinkDetails] =
    React.useState(false);
  const [backupZoomLinkDetails, setBackupZoomLinkDetails] = React.useState({});
  const [showBackupZoomLinkPopup, setShowBackupZoomLinkPopup] =
    React.useState(false);
  const [showMeetingNotScheduledPopup, setShowMeetingNotScheduledPopup] =
    React.useState(false);
  const closeMeetingNotScheduledPopup = () => {
    setShowMeetingNotScheduledPopup(false);
  };
  const [showPasswordError, setShowPasswordError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);

  const setMoreMenuIsOpen = Boolean(anchorEl);
  const moreMenuId = setMoreMenuIsOpen ? "simple-popover" : undefined;

  const onMoreMenuClose = (e) => {
    // why do we need e.stopPropagation(); on close of material ui Popover component
    // https://stackoverflow.com/a/68187250
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onOpenMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const isMoreMenuItemAllowed = (data) => {
  //   const hiddenTabs =
  //     permissions?.modify_items?.exlycon?.exlycon?.hidden_tabs || [];

  //   return !hiddenTabs.includes(data);
  // };

  const copyLink = (link) => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(link);
      notify("Link Copied!", notification_color_keys.success);
    }
  };

  const copyCustomerZoomLink = async () => {
    try {
      const response = await dataProvider.custom_request(
        EXLY_CONNECT_APIS.fetch_customer_zoom_link,
        apiMethods.GET,
        { listing_uuid: data.listing_uuid }
      );
      if (isRequestSuccessful(response.status) && response?.data?.zoom_url) {
        const url = response.data.zoom_url;
        copyLink(url);
      } else {
        copyLink(data.venue_details?.exly_zoom_link_customer);
      }
    } catch (error) {
      copyLink(data.venue_details?.exly_zoom_link_customer);
      logError({
        error,
        occuredAt:
          "src/features/ExlyConnect/modules/ExlyConnectMeetingCard/index.js",
        when: "copyCustomerZoomLink",
      });
      // notify(
      //   error?.body?.message ?? SOMETHING_WENT_WRONG,
      //   notification_color_keys.error
      // );
    }
  };

  const onLaunchMeetingClick = () => {
    if (
      moment(data.startDateTime).startOf("day").isSame(moment().startOf("day"))
    ) {
      window.open(data.venue_details?.joining_link, "_blank");
    } else {
      setShowMeetingNotScheduledPopup(true);
    }
  };

  // const onFetchZoomLinkClick = async (e) => {
  //   onMoreMenuClose(e);
  //   setBackupZoomLinkDetails({
  //     listing_uuid: data.listing_uuid,
  //   });

  //   try {
  //     const backupZoomLinkApiData = await dataProvider.custom_request(
  //       EXLY_CONNECT_APIS.get_backup_zoom_link,
  //       "GET",
  //       { listing_uuid: data.listing_uuid }
  //     );
  //     copyLink(backupZoomLinkApiData?.data?.zoom_link);
  //     setBackupZoomLinkDetails({
  //       listing_uuid: data.listing_uuid,
  //       link: backupZoomLinkApiData?.data?.zoom_link,
  //     });
  //   } catch (error) {
  //     logError({
  //       error,
  //       when: "onFetchZoomLinkClick",
  //       occuredAt:
  //         "src/features/ExlyConnect/modules/ExlyConnectMeetingCard/index.js",
  //     });
  //     notify(
  //       error?.body?.message ?? SOMETHING_WENT_WRONG,
  //       notification_color_keys.error
  //     );
  //   }
  // };

  const onBroadcastMeetingLinkClick = async (e) => {
    onMoreMenuClose(e);
    try {
      await dataProvider.custom_request(
        EXLY_CONNECT_APIS.broadcast_exly_con_reminders,
        apiMethods.GET,
        { slot_uuid: data.uuid }
      );
      notify("Notification sent to customers", notification_color_keys.success);
    } catch (err) {
      notify(
        err?.body?.message ||
          "An error occured. Please try again after some time.",
        "error"
      );
      console.log(err);
    }
  };

  const onCreateZoomBackupLinkClick = async () => {
    if (fetchingBackupZoomLinkDetails) return;
    if (!backupZoomLinkDetails?.password || passwordError) {
      notify("Please enter a valid passwrod", "error");
      return;
    }
    setFetchingBackupZoomLinkDetails(true);

    try {
      const backupZoomLinkApiData = await dataProvider.custom_request(
        EXLY_CONNECT_APIS.create_backup_zoom_link,
        "POST",
        {
          listing_uuid: backupZoomLinkDetails?.listing_uuid,
          password: backupZoomLinkDetails?.password,
        }
      );
      setBackupZoomLinkDetails({
        listing_uuid: backupZoomLinkDetails?.listing_uuid,
        link: backupZoomLinkApiData?.data?.zoom_link,
      });
    } catch (err) {
      console.log(err);
      notify("An error occured. Please try again after some time.", "error");
    }
    setFetchingBackupZoomLinkDetails(false);
  };

  const onPasswordChange = (e) => {
    const password = e.target.value || "";
    setPasswordError(
      composeValidators(
        required,
        noSpaces("No spaces allowed"),
        minLength(8, "Must be at least 8 characters"),
        maxLength(10, "Must be at most 10 characters"),
        alphaNumeric,
        atLeastOneAlphabet,
        atLeastOneNumber
      )(password)
    );

    setBackupZoomLinkDetails({
      ...backupZoomLinkDetails,
      password: password,
    });
  };

  const onZoomLinkModalClose = () => {
    setShowBackupZoomLinkPopup(false);
  };

  return (
    <>
      <Card
        key={data.uuid}
        shadow="shadow_lg"
        variant="outlined"
        CardClassName={styles.exyConItem}
      >
        <img
          alt={data.title}
          className={styles.offeringImage}
          src={data.cover_image}
        />
        <div className="d-flex flex-column w-100">
          <div className="flex-grow-1">
            <div className={styles.offeringTitle}>{data.title}</div>
            {!hideTime && (
              <div className={styles.offeringTimimg}>
                <b>Time:</b>{" "}
                {`${format(
                  new Date(data.start_datetime),
                  DEFAULT_TIME_FORMAT_12
                )} - ${format(
                  new Date(data.end_datetime),
                  DEFAULT_TIME_FORMAT_12
                )}`}
              </div>
            )}

            {!hideDate && (
              <div className={styles.offeringTimimg}>
                <b>Date:</b>{" "}
                {`${format(
                  new Date(data.start_datetime),
                  DATE_WITH_FULL_MONTH_FORMAT
                )}`}
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              onClick={onLaunchMeetingClick}
              color="primary"
              className={styles.launchMeeting}
            >
              Launch Meeting
            </Button>
            <Button
              onClick={onOpenMoreClick}
              className={styles.moreMenuOpenButton}
            >
              <MoreHorizIcon htmlColor="#493ab1" />
              <Popover
                id={moreMenuId}
                open={setMoreMenuIsOpen}
                anchorEl={anchorEl}
                onClose={onMoreMenuClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <div className={styles.moreMenu}>
                  {/* <div
                    className={styles.moreMenuItem}
                    onClick={(e) => {
                      onMoreMenuClose(e);
                      copyLink(data.venue_details?.joining_link);
                    }}
                  >
                    Copy your link
                  </div> */}
                  {/* <div
                    className={styles.moreMenuItem}
                    onClick={(e) => {
                      onMoreMenuClose(e);
                      copyLink(data.venue_details?.exly_zoom_link_customer);
                    }}
                  >
                    Copy customer link
                  </div> */}
                  <div
                    className={styles.moreMenuItem}
                    onClick={(e) => {
                      onMoreMenuClose(e);
                      copyCustomerZoomLink();
                    }}
                  >
                    Copy customer link
                  </div>
                  {/* {isMoreMenuItemAllowed("back_up_link") ? (
                    <div
                      className={styles.moreMenuItem}
                      onClick={onFetchZoomLinkClick}
                    >
                      Copy your zoom link
                    </div>
                  ) : null} */}
                  {!hideBroadcastMeetingLink && (
                    <div
                      className={styles.moreMenuItem}
                      onClick={onBroadcastMeetingLinkClick}
                    >
                      Broadcast meeting link
                    </div>
                  )}
                </div>
              </Popover>
            </Button>
          </div>
        </div>
      </Card>
      <Modal
        open={showBackupZoomLinkPopup}
        onClose={onZoomLinkModalClose}
        {...(!backupZoomLinkDetails?.link
          ? {
              title: "Create Zoom Link",
              primaryBtnText: fetchingBackupZoomLinkDetails ? "..." : "Create",
              onPrimaryBtnClick: onCreateZoomBackupLinkClick,
              showSecondaryBtn: false,
            }
          : {
              title: "Zoom Link",
              customFooter: <></>,
            })}
      >
        <div className="p-4">
          {!backupZoomLinkDetails?.link ? (
            <>
              <div className="mb-2">Set a Meeting Password*</div>
              <ExlyInput
                input={{
                  value: backupZoomLinkDetails?.password || "",
                  onChange: onPasswordChange,
                }}
                meta={{
                  touched: showPasswordError,
                  error: passwordError,
                }}
                onBlur={() => {
                  setShowPasswordError(true);
                }}
              />
            </>
          ) : (
            <div className="d-flex justify-content-between mb-3 pointer">
              <a
                href={backupZoomLinkDetails?.link || ""}
                target="_blank"
                rel="noreferrer noopener"
                className={`mr-3 ${styles.zoomLink}`}
              >
                {backupZoomLinkDetails?.link}
              </a>
              <FileCopyOutlined
                onClick={() => {
                  copyLink(backupZoomLinkDetails?.link);
                }}
              />
            </div>
          )}
        </div>
      </Modal>

      <Modal
        open={showMeetingNotScheduledPopup}
        onClose={closeMeetingNotScheduledPopup}
        title="Meeting not scheduled"
        primaryBtnText="Close"
        onPrimaryBtnClick={closeMeetingNotScheduledPopup}
        showSecondaryBtn={false}
      >
        <div className="p-4">
          The meeting you are trying to launch is not scheduled for today.
          Please try a meeting scheduled for today.
        </div>
      </Modal>
    </>
  );
};
